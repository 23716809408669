import Image from 'components/Image';

import { ReadMore } from './update.styled';

function Update({ data }) {
  const { id, title, category, shortDescription, mainImage } = data;

  return (
    <li className="update">
      <Image image={mainImage} alt={title} />
      <h4>{title}</h4>
      <span>{category}</span>
      <p>{shortDescription}</p>
      <ReadMore recordId={id}>Read more</ReadMore>
    </li>
  );
}

export default Update;
