import { graphql } from 'gatsby';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { HorizontalContainer } from 'components/UI/Containers';
import { HeadingBig } from 'components/UI/Headings';
import Update from 'components/Update';
import PaymentButton from 'components/PaymentButton';

const UpdatesTemplate = ({ data, pageContext }) => {
  const { datoCmsUpdatesPage, allDatoCmsUpdate: { edges: updates } } = data;
  const { seo, title } = datoCmsUpdatesPage;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <HorizontalContainer>
        <HeadingBig>{title}</HeadingBig>
        <section>
          <ul>
            {updates.map((update, key) => (
              <Update key={key} data={update.node} />
              ))}
          </ul>
        </section>
        <PaymentButton amount={200} />
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default UpdatesTemplate;

export const query = graphql`
    query UpdatesPageQuery($locale: String!) {
        datoCmsUpdatesPage(locale: { eq: $locale }) {
            locale
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            title
        }
        allDatoCmsUpdate(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id: originalId
                    title
                    shortDescription
                    category
                    mainImage {
                        gatsbyImageData(width: 285)
                    }
                }
            }
        }
    }
`;
